// import { theme } from "@chakra-ui/core";
// 1. Import the theme and merge util
import { theme } from "@chakra-ui/core";
import { merge } from "@chakra-ui/utils";

console.log(theme);
// 2. Extend the theme to include custom colors, fonts, etc.
const customTheme = merge(theme, {
  components: {
    Link: {
      baseStyle: {
        color: "blue.400",
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 900,
        letterSpacing: "lg",
      },
      variants: {
        script: {
          fontFamily: "Bad Script, serif",
          fontWeight: 400,
        },
      },
      defaultProps: {
        variant: "",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 400,
      },
      defaultProps: {
        colorScheme: "yellow",
      },
    },
  },
  styles: {
    global: {
      "header a": {
        color: "inherit",
      },
      a: {
        color: "blue.400",
      },
    },
  },
  colors: {
    oceanBlue: "#AEC4D2",
    teal: {
      200: "#9aced9",
      700: "#1f364a",
    },
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
  },

  fonts: {
    heading: 'Roboto", sans-serif',
    body: "Roboto, system-ui, sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    "2xs": ".6rem",
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
});

export default {
  ...theme,
  // breakpoints: ["30em", "48em", "62em", "80em"],
};
