// import * as React from 'react'
// import { PreviewStoreProvider } from 'gatsby-source-prismic'

import React from "react";
import { ChakraProvider } from "@chakra-ui/core";

import theme from "@src/theme";

export const wrapRootElement = ({ element }) => (
  <ChakraProvider resetCSS theme={theme}>
    {element}
  </ChakraProvider>
);
